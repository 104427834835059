import { Link } from 'react-router-dom'

function Nav() {
  return (
    <nav>
      <ul>
        <li>
          <h1>
            <Link to={'/'}>
              <span className="fa-solid fa-terminal" aria-hidden="true"></span>
              <span>Leonardo Cunha</span>
            </Link>
          </h1>
        </li>
        {/*<li>
          <a href="#projects">Projects</a>
        </li>*/}
        {/*<li>
          <a href="#about">About</a>
        </li>*/}
      </ul>
    </nav>
  )
}

export { Nav }
