import { Nav } from '../components/Nav'
import { Footer } from '../components/Footer'

function Home() {
  return (
    <>
      <Nav />
      <section id="intro">
        <p className="name">
          Hi, my name is <strong>Leonardo Cunha.</strong>
        </p>

        <p>
          I'm a <strong>Software Engineer.</strong>
        </p>

        <p>
          Currently, I'm a freelancer working with <a href='https://www.voxgig.com/' target='_blank'>Voxgig</a>.
        </p >

        <p>
          I live in <strong>Sabará/MG - Brazil. 🇧🇷</strong>
        </p>
      </section >

      <div className="gradient"></div>
      <Footer />
    </>
  )
}

export { Home }
